<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <supply tempType="02" titleName="投入品供应商"></supply>
  </div>
</template>
<script>
  import supply from '@/views/common/supply.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  export default {
    name: 'supplyList',
    components: {
      supply,
      breadcrumb
    },
    data() {
      return {
        breadStaticData:['生产档案管理', '投入品供应商'],
        linkData:[],
        thirdStaticData:[],
        contHeight: ''
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
</style>